import React from "react";
import Navbar from "./Navbar";
import HeroSection from "./HeroSection";
import Services from "./Services";
import Contact from "./Contact";
import Footer from "./Footer";
import InstaFeeds from "./InstaFeeds";


const Home = () => {
    return (
        <div className="home-container">         
            <Navbar /> 
            <HeroSection /> 
            <section id="services">
                <Services /> 
            </section> 
            <section id="contact">             
                <Contact /> 
            </section>
            <InstaFeeds token={process.env.REACT_APP_INS_TOKEN} limit={3} />                   
            <Footer />           
        </div>
      );
};
export default Home;
