import React from 'react';
import './Footer.css';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Enables smooth scrolling
    });
  };
  
  return (
    <footer className="footer bg-dark text-white py-4">
      <div className="container">
        <div className="row">
          {/* First Column */}
          <div className="col-md-6">
            <h5>hello@ondrejstaidl.com</h5>
            <h5>+420 735 294 782</h5>
          </div>
          <div className="footer-social-links">
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <svg className="arrow-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2L12 22M12 22L5 15M12 22L19 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              Instagram
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <svg className="arrow-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2L12 22M12 22L5 15M12 22L19 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              LinkedIn
            </a>
            <a href="https://herohero.co" target="_blank" rel="noopener noreferrer">
              <svg className="arrow-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2L12 22M12 22L5 15M12 22L19 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              Herohero
            </a>
          </div>
          <div className="col-md-6">
            <div className="info-container">
              <h6>{t('footer.info.companyDetails')}</h6>
              <a href="/privacypolicy" className="privacy-link">
                <svg className="arrow-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 2L12 22M12 22L5 15M12 22L19 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                {t('footer.info.privacyPolicy')}
              </a>
            </div> 
            <p>© 2024 amahi.co</p>
          </div>
          
          {/* Second Column */}
          <div className="col-md-6 position-relative">
          <button onClick={scrollToTop} className="btn-custom position-absolute bottom-0 end-0 mb-3 me-3">
            {t('footer.buttons.goToTop')}
          </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
