// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import enTranslation from './locales/en/translation.json';
import czTranslation from './locales/cz/translation.json';
import deTranslation from './locales/de/translation.json';

// Initialize i18n
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    cz: { translation: czTranslation },
    de: { translation: deTranslation },
  },
  lng: 'en', // Default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // React already escapes values to prevent XSS
  },
});

export default i18n;
