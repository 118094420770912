import React from "react";
import { useTranslation } from 'react-i18next';

import Lottie from "lottie-react";
import spinningWheel from "../assets/spinningWheel.json";
import profilePic from "../assets/profile-pic.png";
import igImage from "../assets/igImage.jpg";

// Import custom SVG icons for social card
import heart from "../assets/heart.svg";
import comment from "../assets/comment.svg";
import share from "../assets/share.svg";
import save from "../assets/save.svg";

import "./Social.css";

const Social = ({ feeds = [] }) => {
    const { t } = useTranslation(); 

    return (
      <section className="social-section">
        <div className="container">
            {/* Stay Connected Section */}
            <div className="stay-connected">
                <div className="d-flex align-items-center">
                    <div className="lottie-container">
                        <Lottie animationData={spinningWheel} className="spinning-wheel" />
                    </div>
                    <h2 className="ml-2">{t('social.stayConnected.header')}</h2>
                </div>
                <p>
                    {t('social.stayConnected.description')}
                </p>
              </div>
    
            {/* Instagram Feed Section */}
            <div className="instagram-feed row">
                {feeds.length > 0 ? (
                    feeds.slice(0, 3).map((feed, index) => (
                        <div className="col-md-4 d-flex align-items-center justify-content-center" key={feed.id}>
                            <a href="https://www.instagram.com/ondrejstaidl" target="_blank" rel="noopener noreferrer" className="instagram-card-link">
                                <div className="instagram-card">
                                    <div className="card-header">
                                        <img src={profilePic} alt="Username" className="profile-pic" />
                                        <span>ondrejstaidl</span>
                                    </div>
                                    <div className="card-image">
                                        <img src={feed.media_url} alt={`Instagram Post ${index + 1}`} />
                                    </div>
                                    <div className="card-footer d-flex justify-content-between">
                                        <div className="icons-left">
                                            <img src={heart} alt="Heart Icon" className="icon" />
                                            <img src={comment} alt="Comment Icon" className="icon" />
                                            <img src={share} alt="Share Icon" className="icon" />
                                        </div>
                                        <div className="icon-right">
                                            <img src={save} alt="Save Icon" className="icon" />
                                        </div>
                                    </div>
                                </div>
                            </a>   
                        </div>
                    ))
                ) : (
                    // Display default static images when feeds array is empty
                    [0, 1, 2].map(index => (
                        <div className="col-md-4 d-flex align-items-center justify-content-center" key={index}>
                            <div className="instagram-card">
                                <div className="card-header">
                                    <img src={profilePic} alt="Username" className="profile-pic" />
                                    <span>ondrejstaidl</span>
                                </div>
                                <div className="card-image">
                                    <img src={igImage} alt={`Default Post ${index + 1}`} />
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <div className="icons-left">
                                        <img src={heart} alt="Heart Icon" className="icon" />
                                        <img src={comment} alt="Comment Icon" className="icon" />
                                        <img src={share} alt="Share Icon" className="icon" />
                                    </div>
                                    <div className="icon-right">
                                        <img src={save} alt="Save Icon" className="icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}      
            </div>
        </div>
      </section>
    );
};

export default Social;
