import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Navbar.css'; 
import logo from '../assets/logo.svg';

const Navbar = () => {
  const {t, i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("ENG");

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  }

  const selectLanguage = (lang) => {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang.toLowerCase());
    setDropdownOpen(false);
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container-fluid px-0 px-md-3">
        <a className="navbar-brand d-flex align-items-center" href="/">
          <img src={logo} alt="Logo" className="me-2"/>
          <div>
            <span className="tagline-name">Ondřej Štaidl</span><br />
            <span className="tagline-title">{t('navbar.instructor')}</span>
          </div>
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end text-end" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="#services">{t('navbar.services')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">{t('navbar.contact')}</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#!" id="navbarDropdown" role="button" onClick={toggleDropdown}>
                {selectedLanguage}
              </a>
              <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} aria-labelledby="navbarDropdown">
                <li><a className="dropdown-item" href="#en" onClick={() => selectLanguage('ENG')}>ENG</a></li>
                <li><a className="dropdown-item" href="#cz" onClick={() => selectLanguage('CZ')}>CZ</a></li>
                <li><a className="dropdown-item" href="#de" onClick={() => selectLanguage('DE')}>DE</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
