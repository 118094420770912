import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import './Contact.css';

const Contact = () => {
  const { t } = useTranslation();
  const form = useRef();  // Reference for the form element
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (data) => {
    emailjs.sendForm('service_0i4p42h', 'template_olg2rdf', form.current, 'BeWjgZTGT0GZfJqqK')
      .then((result) => {
        console.log('Email successfully sent:', result.text);
        setSubmitted(true);
      }, (error) => {
        console.error('Failed to send email:', error.text);
      });
  };

  return (
    <div className="contact-section">
      <div className={`card contact-card ${submitted ? 'submitted' : ''}`}>
        {!submitted ? (
          <div className="row">
            <h2 className="h2-contact text-center w-100">{t('contact.header')}</h2>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="col-12 col-md-8">
                <h3>{t('contact.readytodrive')}</h3>
                <p>{t('contact.intro')}</p>
                <p className="contact-alt">hello@ondrejstaidl.com</p>
                <p className="contact-alt">+420 735 294 782</p>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="col-12 col-md-10">
                <form ref={form} onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                    <label htmlFor="name" className="form-label">{t('contact.form.nameLabel')}</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder={t('contact.form.namePlaceholder')}
                        {...register('name', { required: t('contact.form.nameError') })}
                    />
                    {errors.name && <span className="text-danger">{errors.name.message}</span>}
                    </div>
                    <div className="mb-3">
                    <label htmlFor="message" className="form-label">{t('contact.form.messageLabel')}</label>
                    <textarea
                        className="form-control"
                        id="message"
                        placeholder={t('contact.form.messagePlaceholder')}
                        rows="3"
                        {...register('message', { required: t('contact.form.messageError') })}
                    ></textarea>
                    {errors.message && <span className="text-danger">{errors.message.message}</span>}
                    </div>
                    <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="phone" className="form-label">{t('contact.form.phoneLabel')}</label>
                        <input
                        type="tel"
                        className="form-control"
                        placeholder={t('contact.form.phonePlaceholder')}
                        id="phone"
                        {...register('phone', {
                            pattern: {
                            value: /^[0-9]{10,15}$/,
                            message: t('contact.form.phoneError')
                            }
                        })}
                        />
                        {errors.phone && <span className="text-danger">{errors.phone.message}</span>}
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="email" className="form-label">{t('contact.form.emailLabel')}</label>
                        <input
                        type="email"
                        className="form-control"
                        placeholder={t('contact.form.emailPlaceholder')}
                        id="email"
                        {...register('email', {
                            required: t('contact.form.emailErrorRequired'),
                            pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: t('contact.form.emailErrorInvalid')
                            }
                        })}
                        />
                        {errors.email && <span className="text-danger">{errors.email.message}</span>}
                    </div>
                    </div>
                    <button type="submit" className="contact-btn">
                        <svg className="arrow-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 2L12 22M12 22L5 15M12 22L19 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg> {t('contact.form.submitButton')}
                    </button>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className="thank-you-message text-center">
            <h2>{t('contact.thankYou.header')}</h2>
            <p>{t('contact.thankYou.responseTime')}</p>
            <p>{t('contact.thankYou.fasterResponse')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
