import React from 'react';
import Lottie from "lottie-react";
import { useTranslation } from 'react-i18next';
import './HeroSection.css';

import spinningWheel from "../assets/spinningWheel.json";
import osProfile from "../assets/os-profile.jpg";

const HeroSection = () => {
  const { t } = useTranslation();
    return (
    <>
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          {/* Heading Left */}
          <div className="heading-container">
            <h1 className="heading-left">
              <span>{t('hero-section.drivenby')}</span>
              <br />
              <span>{t('hero-section.passion')}</span>
            </h1>
          </div> 

          {/* Hero Paragraph */}
          <div className="hero-paragraph">
          {t('hero-section.paragraph')}
          </div>

          {/* Heading Right */}
          <div className="heading-container">
            <h1 className="heading-right">
              <span>{t('hero-section.perfectedby')}</span>
              <br />
              <span>{t('hero-section.technique')}</span>
            </h1>
          </div> 

          {/* CTA and Social Links */}
          <div className="hero-footer">
            <div className="social-links">
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <svg className="arrow-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 2L12 22M12 22L5 15M12 22L19 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                Instagram
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                <svg className="arrow-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 2L12 22M12 22L5 15M12 22L19 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                LinkedIn
              </a>
              <a href="https://herohero.co" target="_blank" rel="noopener noreferrer">
                <svg className="arrow-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 2L12 22M12 22L5 15M12 22L19 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                Herohero
              </a>
            </div>
            <button className="cta-button">
              <svg className="arrow-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2L12 22M12 22L5 15M12 22L19 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              {t('hero-section.cta-button')}
            </button>
          </div>
        </div>
      </section>

      {/* Second Viewport Section */}
      <section className="second-viewport">
        <div className="containercontainer-lg">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-4">
              <img src={osProfile} alt="Who I Am" className="img-fluid profile-image" />
            </div>
            <div className="col-12 col-md-8 text-content">
              <div className="lottie-heading-container">
                <div className="lottie-container">
                  <Lottie animationData={spinningWheel} className="spinning-wheel" />
                </div>
                <h2 className="ml-2">{t('hero-section.whoiam.title')}</h2>
              </div>
              <p>
               {t('hero-section.whoiam.description')}
              </p>
              <p className="who-i-am">
                {t('hero-section.whoiam.details')}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
