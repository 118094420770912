import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Social from "./Social";

const InstaFeeds = ({ token, limit }) => {
    const [feeds, setFeedsData] = useState([]);
    const tokenProp = useRef(token);
    tokenProp.current = token;

    useEffect(() => {
        const abortController = new AbortController();

        async function fetchInstagramPost() {
            try {
                const response = await axios.get(
                    `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=${limit}&access_token=${tokenProp.current}`, 
                    { signal: abortController.signal }
                );
                console.log('API Response:', response.data);

                if (response.data && response.data.data) {
                    setFeedsData(response.data.data);
                } else {
                    console.log('No data in API response.');
                }
            } catch (err) {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message);
                } else {
                    console.log('Error fetching data:', err.message);
                }
            }
        }

        fetchInstagramPost();

        return () => {
            abortController.abort();
        };
    }, [limit]);

    return (
        <Social feeds={feeds} />
    );
}

export default InstaFeeds;
