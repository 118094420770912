import React from "react";
import { useTranslation } from 'react-i18next';
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Navbar />
            
            {/* Main section with the privacy policy content */}
            <main className="container privacy-policy-content">
                <h1>{t('privacyPolicy.header')}</h1>
                <h4>{t('privacyPolicy.lastUpdated')}</h4>
                <p>
                    {t('privacyPolicy.intro')}
                </p>
                {/* Section 1 */}
                <h2>{t('privacyPolicy.sections.informationWeCollect.title')}</h2>
                <h3>{t('privacyPolicy.sections.informationWeCollect.personalInfoTitle')}</h3>
                <p>
                    {t('privacyPolicy.sections.informationWeCollect.personalInfoDescription')}
                </p>    
                <h3>{t('privacyPolicy.sections.informationWeCollect.autoCollectedInfoTitle')}</h3>
                <p>
                    {t('privacyPolicy.sections.informationWeCollect.autoCollectedInfoDescription')}
                </p>
                <h3>{t('privacyPolicy.sections.informationWeCollect.instagramApiTitle')}</h3>
                <p>
                    {t('privacyPolicy.sections.informationWeCollect.instagramApiDescription')}
                </p>
                {/* Section 2 */}
                <h2>{t('privacyPolicy.sections.howWeUseYourInformation.title')}</h2>
                <h3>{t('privacyPolicy.sections.howWeUseYourInformation.description')}</h3>
                <ul>
                    {t('privacyPolicy.sections.howWeUseYourInformation.purposes', { returnObjects: true }).map((purpose, index) => (
                        <li key={index}>{purpose}</li>
                    ))}
                </ul>  
                {/* Section 3 */}
                <h2>{t('privacyPolicy.sections.sharingYourInformation.title')}</h2>
                <h3>{t('privacyPolicy.sections.sharingYourInformation.description')}</h3>
                <ul>
                    {t('privacyPolicy.sections.sharingYourInformation.situations', { returnObjects: true }).map((situation, index) => (
                        <li key={index}>{situation}</li>
                    ))}
                </ul>
                {/* Section 4 */}
                <h2>{t('privacyPolicy.sections.cookiesAndTracking.title')}</h2>
                <p>
                    {t('privacyPolicy.sections.cookiesAndTracking.description')}
                </p>  
                {/* Section 5 */}
                <h2>{t('privacyPolicy.sections.yourRights.title')}</h2>
                <h3>{t('privacyPolicy.sections.yourRights.description')}</h3>
                <ul>
                    {t('privacyPolicy.sections.yourRights.rights', { returnObjects: true }).map((right, index) => (
                        <li key={index}>{right}</li>
                    ))}
                </ul> 
                {/* Section 6 */}
                <h2>{t('privacyPolicy.sections.dataSecurity.title')}</h2>
                <p>
                    {t('privacyPolicy.sections.dataSecurity.description')}
                </p>
                {/* Section 7 */}
                <h2>{t('privacyPolicy.sections.linksToOtherWebsites.title')}</h2>
                <p>
                    {t('privacyPolicy.sections.linksToOtherWebsites.description')}
                </p>
                {/* Section 8 */}
                <h2>{t('privacyPolicy.sections.changesToPolicy.title')}</h2>
                <p>
                    {t('privacyPolicy.sections.changesToPolicy.description')}
                </p>  
                {/* Section 9 */}
                <h2>{t('privacyPolicy.sections.contactUs.title')}</h2>
                <p>
                    {t('privacyPolicy.sections.contactUs.description')}
                </p>  
            </main>            
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
